<template>
  <div class="home">
     <!-- CONTENIDO -->
    <div class="container">
      <!-- BREADCRUM + LOGOTIPO + DATOS DE USUARIO -->
      <div class="row top-buffer">
        <div class="col-md-12">
          <ol class="breadcrumb">
            <li><a href="https://www.gob.mx"><i class="icon icon-home"></i></a></li>
            <li><a href="https://www.gob.mx/sct">SICT</a></li>
            <li class="active">Portal Cobertura Universal</li>
          </ol>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" id="logotipo" >
          <img   src="../assets/img/logo.png" alt="Secretaría de Comunicaciones y Transportes" />
        </div>
      </div>

      <!-- TÍTULO Y SUBTÍTULO -->
      <div class="row">
        <div class="col-md-12">
          <h1>Portal Cobertura Universal</h1>
          <h2>Bienvenidas y bienvenidos</h2>
          <hr class="red" style="margin-bottom:40px;">
          <!--<p class="text-center" style="margin-bottom:32px;font-size:16px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vehicula molestie justo, eget viverra felis aliquam pellentesque. Mauris pellentesque massa a ipsum aliquet, a
            sodales dui fringilla. Nam laoreet, est acconsequat aliquam.</p> -->
        </div>
      </div>

      <!-- CONTENIDO -->
      <div class="row">
        <div class="col-md-12">
          <p class="text-justify">Aquí encontrarás las políticas públicas, los proyectos, estrategias y programas que la SCT emprende y desarrolla para promover la cobertura y penetración de los servicios de telecomunicaciones, incluyendo banda ancha
            e internet, en todo el territorio nacional.</p>
          <p class="text-justify">De acuerdo al signo de los tiempos, las acciones de la SCT para lograr la cobertura universal están orientadas a propiciar el acceso equitativo de toda la población a las telecomunicaciones e Internet en condiciones
            de disponibilidad y asequibilidad, como el medio más efectivo para ofrecer igualdad de oportunidades a todas las personas y así elevar su calidad de vida, al tiempo en que se impulsa el desarrollo tecnológico, la productividad y la
            competitividad del país.</p>
          <p class="text-justify">En línea con este propósito, en este portal se encuentra la definición conceptual, el estado y los avances de los siguientes proyectos y programas:</p>
          <ul>
            <li class="text-justify"><strong>Aldeas Inteligentes, Bienestar Sostenible</strong></li>
            <li class="text-justify"><strong>Programas de Cobertura Social y de Conectividad en Sitios Públicos</strong></li>
            <li class="text-justify"><strong>Repositorio Único de sitios públicos conectados y por conectar</strong></li>
            <li class="text-justify"><strong>Desarrollo y Marco de Habilidades Digitales</strong></li>
            <li class="text-justify"><strong>Mejores prácticas y estrategias de impulso a la Ciberseguridad</strong></li>
          </ul>
          <p class="text-justify small-bottom-buffer">Te invitamos a explorar este Portal y a conocer el detalle de las acciones que la SCT emprende para apoyar uno de los proyectos estratégicos de la actual administración que es incrementar el
            acceso a las telecomunicaciones y la conectividad a Internet en todo México.</p>
        </div>
      </div>

      <!-- PRIMER RENGLÓN -->
      <div class="row">
        <div class="col-md-4">
          <div class="card text- card-gob">
            <div class="titulocategoria">
              <a href="/aldeas-inteligentes">
                <img class="card-img-top card-img-gob" src="../assets/img/logo_Aldeas_inteligentes.png" alt="Logotipo del Aldeas Inteligentes">
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &ensp; &ensp;
                <img class="card-img-img" src="../assets/img/wsispp_w-min.png" alt="Reconocimiento">
                
                <h2 class="card-title">Aldeas Inteligentes,<br> Bienestar Sostenible</h2>
              </a>
            </div>
            <div class="card-body">
              <p>Aprovechamiento de las posibilidades y los recursos de la conectividad digital en comunidades rurales.</p>
              <a class="btn btn-primary btn-sm xsmall-top-buffer" type="button" href = '/aldeas-inteligentes'>Más información</a>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card text-center card-gob">
            <div class="titulocategoria">
              <a href="/conectividad-sitios-publicos">
                <img class="card-img-top card-img-gob" src="../assets/img/logo_Conectividad.png" alt="Logotipo del Programa de Conectividad en Sitios Públicos 2020-2021">
                <h2 class="card-title">Programa de Conectividad en Sitios Públicos</h2>
              </a>
            </div>
            <div class="card-body">
              <a class="btn btn-primary btn-sm small-top-buffer" type="button" href = '/conectividad-sitios-publicosInter'>Más información</a>
            </div>
          </div>
        </div>
  		  <div class="col-md-4">
          <div class="card text-center card-gob">
            <div class="titulocategoria">
              <a href="/repositorio-unico">
                <img class="card-img-top card-img-gob" src="../assets/img/logo_Repositorio_unico.png" alt="Logotipo del Repositorio Único de Sitios Públicos conectados y por conectar">
                <h2 class="card-title">Repositorio Único de Sitios Públicos conectados y por conectar</h2>
              </a>
            </div>
            <div class="card-body">
              <a class="btn btn-primary btn-sm small-top-buffer" type="button" href = '/repositorio-unico'>Más información</a>
            </div>
          </div>
        </div>
      </div><!--Primer renglón-->
  	  <!--SEGUNDO RENGLÓN-->
      <div class="row">
        <div class="col-md-4">
          <div class="card text-center card-gob">
            <div class="titulocategoria">
              <a href="/cobertura-socialMenu">
                <img class="card-img-top card-img-gob" src="../assets/img/logo_Cobertura_Social.png" alt="Logotipo del Programa de Cobertura Social">
                <h2 class="card-title">Programa de Cobertura Social </h2>
              </a>
            </div>
            <div class="card-body">
              <a class="btn btn-primary btn-sm small-top-buffer" type="button" href = '/cobertura-socialMenu'>Más información</a>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card text-center card-gob">
            <div class="titulocategoria">
              <a href="/preguntas-frecuentes">
                <img class="card-img-top card-img-gob" src="../assets/img/logo_Preguntas.png" alt="Preguntas frecuentes">
                <h2 class="card-title">Preguntas frecuentes</h2>
              </a>
            </div>
            <div class="card-body">
              <a class="btn btn-primary btn-sm small-top-buffer" type="button" href = '/preguntas-frecuentes'>Más información</a>
            </div>
          </div>
        </div>
      </div><!--Segundo renglón-->
      <!--TERCER RENGLÓN-->
      <div class="row">
        <div class="col-md-4">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../router"
export default {
  name: 'home',
   methods: {
    questions(){
        router.replace('/preguntas-frecuentes')
    },
  },
}
</script>

